import { Pipe, PipeTransform } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from '@shared/services/configuration.service';

@Pipe({
    pure: true,
    name: 'currencyDisplay',
    standalone: true
})
export class CurrencyDisplayPipe implements PipeTransform {

  constructor(private configService: ConfigurationService,
              private translateService: TranslateService) {
  }

  public transform(val: number | string | null | undefined, options?: { currency?: string, format?: string, minDecimals?: number, maxDecimals?: number }): string {
    const currency = options?.currency ?? this.configService.configuration!.nativeCurrency;
    const format = options?.format ?? '%s %c';
    const minDecimals = options?.minDecimals ?? (options?.currency === '%' ? 1 : 0);
    const maxDecimals = options?.maxDecimals ?? (options?.currency === '%' ? 1 : 0);

    const lang = this.translateService.currentLang;

    if (val !== null && val !== undefined) {
      val = new Intl.NumberFormat(lang, {
        minimumFractionDigits: minDecimals,
        maximumFractionDigits: maxDecimals
      }).format(+val);
    }
    return format
      .replace('%s', val ? val.toString() : '')
      .replace('%c', getCurrencySymbol(currency, 'narrow'));
  }
}
